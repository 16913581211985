const parallaxStyle = {

    parallax: {

        overflow: "hidden",
        position: "relative",
        margin: "0",
        padding: "0",
        border: "0",
        alignItems: "center",



        "@media screen and (min-width: 1024px)": {
            height: "800px",
            backgroundPosition: "bottom center",
            backgroundSize: "cover",

        },
        "@media screen and (min-width: 768px) and (max-width: 1024px)": {
            height: "600px",
            backgroundPosition: "bottom center",
            backgroundSize: "cover",

        },

        "@media screen and (max-width: 640px)": {
            height: "560px",
            backgroundPosition: "top left",
            backgroundSize: "cover",
        }

    },

    filter: {
        "&:before": {
            background: "rgba(0, 0, 0, 0.5)"
        },
    }
};

export default parallaxStyle;