import {
    container,
    title,
} from "./nextjs-material-kit-pro.js";

const componentsStyle = {

    container: {
        ...container,
        zIndex: "2",
        color: "#FFFFFF",
        position: "relative"
    },
    title: {
        ...title,
        color: "white" + "  !important",
        marginTop: "0px",
        marginBottom: "25px",
        minHeight: "32px",
        "@media screen and (max-width:500px)": {
            fontSize: "28px"
        },
        "@media screen and (min-width: 1024px)": {
            position: "relative",
            top: "200px",

        },
        "@media screen and (min-width: 768px) and (max-width: 1024px)": {
            position: "relative",
            top: "200px",

        },

        "@media screen and (max-width: 640px)": {
            position: "relative",
            top: "60px",
        }
    },

    description: {
        color: "white" + "!important",
        "@media screen and (min-width: 1024px)": {
            position: "relative",
            top: "200px",

        },
        "@media screen and (min-width: 768px) and (max-width: 1024px)": {
            position: "relative",
            top: "200px",

        },

        "@media screen and (max-width: 640px)": {
            position: "relative",
            top: "80px",
        }
    }

};

export default componentsStyle;