import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import styles from "../jss/thankYou.js"

// core components
import Header from "../components/Header/Header.jsx"
import Footer from "../components/Footer/Footer.jsx"
import Grid from "@material-ui/core/Grid"

import HeaderLinks from "../components/Header/HeaderLinks.jsx"
import Parallax from "../components/Parallax/thankYou.jsx"

import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo"

const useStyles = makeStyles(styles)

export default function LandingPage(props) {
  const classes = useStyles()
  const { ...rest } = props
  return (
    <div>
      <LogoJsonLd
        logo="https://storage.googleapis.com/product-image-upload/web-design-toronto.png"
        url="https://atlasagency.ca"
      />
      <GatsbySeo
        title="Atlas Agency | Thank You | Toronto Web Design"
        description="Thank you for your interest our team of web designers in toronto will get back to you."
        canonical="https://atlasagency.ca/thank-you/"
        openGraph={{
          type: "website",
          locale: "en_IE",
          description:
            "Web design Toronto based company. We provide web design and SEO services for companies of all sizes. All of our web designs are mobile and desktop optimized making the user experience seamless and quick.",
          url: "https://atlasagency.ca",
          site_name: "Atlas Agency | Web Design Toronto",
          images: [
            {
              url:
                "https://storage.googleapis.com/product-image-upload/toronto-web-design2.png",
              width: 800,
              height: 450,
              alt: "Web Design Toronto",
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
          site: "@AtlasAgencyCA",
        }}
      />
      <Header
        color="transparent"
        brand="Atlas Agency"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 60,
          color: "dark",
        }}
        {...rest}
      />
      <Parallax filter responsive image="/contact-toronto-developer.jpg">
        <div className={classes.container} style={{ marginTop: "80px" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <h1 className={classes.title}>
                Thank You For Connecting With Us
              </h1>
              <p className={classes.description}>
                Thank you for contacting us. We will get back to you within 24
                hours to discuss the next steps. We are excited to help you with
                your next website design and SEO optimization.
              </p>
              <br />
            </Grid>
          </Grid>
        </div>
      </Parallax>
      <Footer />
    </div>
  )
}
